// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-job-template-js": () => import("./../../../src/templates/job-template.js" /* webpackChunkName: "component---src-templates-job-template-js" */),
  "component---src-templates-jobs-template-js": () => import("./../../../src/templates/jobs-template.js" /* webpackChunkName: "component---src-templates-jobs-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */),
  "component---src-templates-referenzen-template-js": () => import("./../../../src/templates/referenzen-template.js" /* webpackChunkName: "component---src-templates-referenzen-template-js" */)
}

